import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import './styles.scss';
import '../../../vendor/assets/js/theme';
import * as bootstrap from 'bootstrap';

Rails.start();
ActiveStorage.start();

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('[data-bs-toggle="tooltip"]')
    .forEach((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));
});
